<template>
	<div class="news">
		<w-navTab :titleText="titleText"></w-navTab>
		<w-list class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="head-nav">
				<div :class="['head-nav-top', className('head-nav-top-')]">
					<div :class="[{ color: active == key }]" @click="onChange(key)" v-for="(vo, key) in nav">
						{{ vo.title }}
					</div>
				</div>
			</div>
			<div class="middle" v-show="newsList.length > 0">
				<NewsList :news-list="newsList"></NewsList>
			</div>
		</w-list>
	</div>
</template>

<script>
import NewsApi from '@/api/news';
import NewsList from '@/components/NewsList';
import VantVendor from '@/vendor/vant';
import WList from '@/components/List';
import keepMiXin from '@/mixins/keep';
import scrollMiXin from '@/mixins/scroll';
import UtilsHelper from '@/vendor/utils';

const keep = keepMiXin(['NewsDetail']);
const scroll = scrollMiXin(['.list-view']);

export default {
	name: 'News',
	mixins: [scroll, keep],
	inject: ['className'],
	data() {
		let active = 0;
		let nav = UtilsHelper.getCurrentDistrictConfig().column.page;
		let columnId = nav[0].id;
		
		return {
			active: active,
			nav: nav,
			newsList: [],
			loading: true,
			finished: false,
			isEmpty: false,
			paramsPage: 1,
			paramsLimit: 10,
			paramsColumnId: columnId,
			titleText:''
		};
	},
	created() {
		this.titleText = this.nav[this.active].title
		this.onLoad();
	},
	methods: {
		onChange(index) {
			if(this.active != index){
				this.active = index;
				this.titleText = this.nav[this.active].title
				this.paramsColumnId = this.nav[index].id;
				this.finished = false;
				this.isEmpty = false;
				this.paramsPage = 1;
				this.newsList = [];
				this.onLoad();
			}
		},
		onLoad() {
			this.loading = true;
			
			let params = {
				page: this.paramsPage,
				limit: this.paramsLimit,
				column_id: this.paramsColumnId
			};
			
			NewsApi.articleList(params)
				.then(result => {
					this.paramsPage++;
					this.newsList = this.newsList.concat(result.data.list);
					
					this.loading = false;
					
					if (this.newsList.length <= 0) {
						this.isEmpty = true;
					}
					
					if (this.newsList.length >= result.data.total) {
						this.finished = true;
					}
				})
				.catch(error => {
					VantVendor.Toast(error.msg);
				});
		}
	},
	components: {
		WList,
		NewsList
	}
};
</script>

<style lang="less" scoped>
.news {
	.list-view{
		height: 100vh;
	}
	.head-nav {
		background-color: #fff;

		.head-nav-top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 18px 20px 0;
			border-bottom: 1px solid #ddd;

			.color {
				font-weight: bold;
			}
		}

		div {
			font-size: 16px;
			line-height: 23px;
			color: #000;
			padding-bottom: 12px;
			margin-bottom: -1px;
		}
	}

	.head-nav-top.head-nav-top-city .color {
		color: #3377ff;
		border-bottom: 2px solid #3377ff;
	}

	.head-nav-top.head-nav-top-district .color {
		color: #ff6969;
		border-bottom: 2px solid #ff6969;
	}

	.middle {
		padding: 10px;
	}
}
</style>
